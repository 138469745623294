<template>
  <div id="quotation-details">
    <!--    <van-nav-bar-->
    <!--      title="报价确认"-->
    <!--      left-arrow-->
    <!--      @click-left="goBack"-->
    <!--    />-->
    <div class="quotationContent" v-if="endInquiryList.engineeringNo!=''&&endInquiryList.engineeringType!=''">
      <div>
        <div class="itemMsg">
          <span>
            项目编号
          </span>
          <span>
           {{ endInquiryList.engineeringNo }}
          </span>
        </div>
        <div class="itemMsg">
           <span>
            项目名称
          </span>
          <span>
            {{ endInquiryList.engineeringName }}
          </span>
        </div>
        <div class="itemMsg">
       <span>
        项目性质
      </span>
          <span>
             {{ endInquiryList.engineeringMainType == 0 ? '工装' : '家装' }}-{{ endInquiryList.engineeringType }}
      </span>
        </div>
        <div class="itemMsg">
       <span>
        采购类型
      </span>
          <span>
             {{ endInquiryList.purchaseType }}
      </span>
        </div>
        <div class="itemMsg">
       <span>
        预计总面积
      </span>
          <span>
         {{ endInquiryList.estimatedTotalArea }}㎡
      </span>
        </div>
        <div class="itemMsg">
       <span>
        最后报价日期
      </span>
          <span>
        {{ endInquiryList.cutoffQuotedDate }}
      </span>
        </div>
      </div>
      <!--        增加一个显示和隐藏判断-->
      <!--        v-if="showEndInquiryList"-->
      <div v-if="showEndInquiryList==true">
        <div class="termOfPayment">
          付款条件
        </div>
        <div class="payment" v-if="endInquiryList.isSubsist==1">
          <span>预估款：</span><span>合同签订、支付{{ endInquiryList.subsistRate }}%</span>
        </div>
        <!--        进度款=支付时间+支付比例（后台）-->
        <div class="payment" v-if="endInquiryList.payRate!=''">
          <span>进度款：</span><span>{{ payType }}{{ endInquiryList.payRate }}%</span>
        </div>
        <!--        质保金=质保金+质保金比例+质保时间（后台）-->
        <div class="payment" v-if="endInquiryList.isWarranty">
          <!--          warrantyCycle  质保金周期-->
          <span>质保金：</span><span>{{ endInquiryList.warranty }}%质保金，质保时间按照最后一批供货时间计算，{{
            endInquiryList.warrantyCycle
          }}个月</span>
        </div>
        <div class="payment" v-if="endInquiryList.remark!=''">
          <span style="width:0.78rem">其他说明：</span><span>{{ endInquiryList.remark }}</span>
        </div>
      </div>
      <div class="iphone">
        <div class="item">
          <p class="itemTitle">
            项目秘书
          </p>
          <p class="itemTel">
            <!--         <van-image :src="userLogo">-->

            <!--         </van-image>-->
            <img :src="userIcon" alt="">
            <span class="itemName" style="margin-left:10px;">
            姓名：
          </span>
            <span style="color:#666666;" class="itemName">
            {{ endInquiryList.secretaryName }}
          </span>
          </p>
          <p class="itemTel">
            <!--         <van-image :src="userLogo">-->

            <!--         </van-image>-->
            <img :src="phoneIcon" alt="">
            <span class="itemName" style="margin-left:10px;">
            电话：
          </span>
            <span style="color:#666666;" class="itemName">
             {{ endInquiryList.secretaryPhone }}
          </span>
          </p>
        </div>
        <div class="imgBorder">
          <!--        userLogo-->
          <img :src="endInquiryList.secretaryQrCode" alt="">
        </div>
      </div>
    </div>
    <!--    卡片列表-->
    <quote-card :dataList="endInquiryList.liEngineeringItems" :end-inquiry-id="query.endInquiryId" :isShowArrow="true"
                :isPadding="false"></quote-card>
    <!--    其他要求-->
    <div class="request">
      <div class="termOfPayment" v-if="endInquiryList.clientRemark!=''">
        其它要求
      </div>
      <div class="payment" v-if="endInquiryList.clientRemark!=''">
        <!--        <span>1：</span>-->
        <span style="width:8.7rem">{{ endInquiryList.clientRemark }}</span>
      </div>
      <!--      <div class="payment">-->
      <!--        <span>2：</span ><span style="width:8.7rem">生产排板的时候客户要一起审查</span>-->
      <!--      </div>-->
      <!--      <div class="payment">-->
      <!--        <span>3：</span><span style="width:8.7rem">客户希望现场施工时有服务人员监管，客户不一定都在现场</span>-->
      <!--      </div>-->
      <div class="payment" v-for="(url,index) in endInquiryList.clientImgList" :key="index">
        <!--        <span>其他说明：</span><span>xxxxx</span>-->
        <van-image :src="url" alt="">
        </van-image>
      </div>
    </div>
    <van-button class="btn" @click="btnPrice">
      {{ startQuoted }}
    </van-button>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import {useRoute, useRouter} from 'vue-router'
import {getEngineeringInfo, getEngineeringInfoNoToken} from '@/api/quitedPrice'
import quoteCard from "./component/quoteCard.vue";

const {Session} = require("../../utils/storage");

export default {
  name: "quotationDetails",
  components: {quoteCard},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      // isPsInput: false,
      // showLoading: true,
      // // 报价密码错误提示
      // pwError: false,
      // pwMsg:'',
      //密码
      passWord: '',
      startQuoted: '开始报价',
      userIcon: require('../../assets/quotedPrice/user.png'),
      phoneIcon: require('../../assets/quotedPrice/phone.png'),
      endInquiryId: '',
      query: {
        endInquiryId: '',
      },
      //获取询盘详情列表
      endInquiryList: [],
      // 支付类型
      payType: '',
      //报价验证
      iSquoted: {
        endInquiryId: '',
        quotedPricePassword: '',
        quotedListId: ''
      },
      showEndInquiryList: true,
    })
    //点击开始报价
    const btnPrice = () => {
      if (state.startQuoted === '联系客服') {
        window.location.href = "https://qiyukf.com/client?k=a346da28ce60080e619ece97a6193f54&wp=1"
      } else {
        let url = '/quotedPrice';
        let obj = {
          endInquiryId: state.query.endInquiryId
        }
        if (!Session.get('token')) {
          url = '/login'
          obj.url = '/quotedPrice'
        }
        router.push({
          path: url,
          query: obj
        })
      }

    }
    // 请求详情 无需token
    const getEngineeringNoTokenFn = () => {
      console.log(121111111511515)
      getEngineeringInfoNoToken(state.query).then(res => {
        console.log(res)
        state.showLoading = false
        state.endInquiryList = res.data
        dataDispose();
        state.endInquiryList.isAssignQuotedPrice == 0 ? state.startQuoted = '联系客服' : '开始报价'
      })
    }

    // 详情接口返回数据后处理
    const dataDispose = () => {
      //付款条件的判断显示或者隐藏
      state.showEndInquiryList = state.endInquiryList.isWarranty == 1 || state.endInquiryList.isSubsist == 1 || state.endInquiryList.isPay == 1 || state.endInquiryList.remark != '' ? true : false
      switch (state.endInquiryList.engineeringType != '') {
        case state.endInquiryList.engineeringType == 0:
          state.endInquiryList.engineeringType = "精装房";
          break;
        case state.endInquiryList.engineeringType == 1:
          state.endInquiryList.engineeringType = "商业办公";
          break;
        case state.endInquiryList.engineeringType == 2:
          state.endInquiryList.engineeringType = "酒店";
          break;
        case state.endInquiryList.engineeringType == 3:
          state.endInquiryList.engineeringType = "行政办公";
          break;
        case state.endInquiryList.engineeringType == 4:
          state.endInquiryList.engineeringType = "平层";
          break;
        case state.endInquiryList.engineeringType == 5:
          state.endInquiryList.engineeringType = "别墅";
          break;
        case state.endInquiryList.engineeringType == 6:
          state.endInquiryList.engineeringType = "套房";
          // window.console.log("111123")
          break;
        case state.endInquiryList.engineeringType == 7:
          state.endInquiryList.engineeringType = "独栋";
          // window.console.log("123123")
          break;
      }
      // state.endInquiryList.engineeringType = state.endInquiryList.engineeringType == 0 ? '精装房' : state.endInquiryList.engineeringType == 1 ? '商业办公' : state.endInquiryList.engineeringType == 2 ? '酒店' : state.endInquiryList.engineeringType == 3 ? '行政办公' :
      //支付类型  0-发货前，1-货到现场，2-验收后
      // LocalStorage.set('quoteList', JSON.stringify(res.liEngineeringItems))
      // window.console.log(res.quotedPriceStatus,"res.quotedPriceStatus")
      state.endInquiryList.payType == 0 ? state.payType = '每批发货前支付到' : state.endInquiryList.payType == 1 ? state.payType = '每批货到现场支付到' : state.payType = '工地验收后一个月内支付到'
    }

    // 请求详情
    const getEngineering = () => {
      getEngineeringInfo(state.query).then(res => {
        // window.console.log(res, "RES")
        state.showLoading = false
        state.endInquiryList = res.data
        dataDispose()
        state.endInquiryList.quotedPriceStatus == 2 ? state.startQuoted = '查询报价' : ''
      })
    }
    const goBack = () => {
      // window.history.go(-1);
      router.push('/quotationList')
    }
    //
    onMounted(() => {
      window.document.title = '报价确认';
      // console.log(route)
      state.query.endInquiryId = route.query.endInquiryId;

      Session.get('token') ? getEngineering() : getEngineeringNoTokenFn()
    })
    return {
      goBack,
      btnPrice,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0
}

//html
//body
//width 100%
//height 100%
//padding:0 8px;
#quotation-details {
  //padding: 0 16px;
  margin-bottom: 0.3rem;
}

.quotationContent {
  padding: 0 0.16rem;
  margin-bottom: 0.1rem;
}

.itemMsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.1rem;
  line-height: 0.21rem;
  height: 0.21rem;
  //width: 3.45rem;
  width: 100%;
  font-size: 0.15rem;

  span:first-child {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 0.21rem;
  }

  span:last-child {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.21rem;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    //max-width: 2.6rem;
  }
}

.payment {
  display: flex;
  margin-top: 0.1rem;
  justify-content: start;
  line-height: 0.21rem;
  width: 3.45rem;

  //width:280px;
  //justify-content :space-between;
  //align-items :center;

  span:first-child {
    //width: 80px;
    width: 0.6rem;
    //height: 17px;
    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    //line-height: 17px;
    display: inline-block;
    //letter-spacing: 7px;
  }

  span:last-child {
    //width: 245px;
    width: 2.84rem;
    //height: 20px;
    display: inline-block;
    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    //line-height: 20px;
  }
}

.termOfPayment {
  width: 2rem;
  height: 0.17rem;
  font-size: 0.14rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.17rem;
  //letter-spacing: 22px;
  margin: 0.15rem auto 0;
  text-align: center;
}

.iphone {
  width: 3.43rem;
  height: 0.93rem;
  background: #FFFFFF;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  //box-shadow: 0px 0px 4px 0px rgba(208, 153, 47, 0.2);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.05rem;
  margin-top: 0.17rem;
  justify-content: space-between;
  //margin:0 auto;
  padding: 0.08rem 0.1rem 0.08rem 0.12rem
}

img {
  width: 0.18rem;
  height: 0.21rem;

}

.item {
  .itemTitle {
    //width: 49px;
    //height: 17px;
    font-size: 0.12rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.17rem;
  }

}

.itemTel {
  display: flex;
  align-items: center;
  height: 0.3rem;

  .itemName {
    //width: 73px;
    //height: 17px;
    font-size: 0.12rem;
    //margin-left:10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    //color: #D0992F;
    color: #3967A2;
    line-height: 0.27rem;
  }

}

.imgBorder {
  width: 0.76rem;
  height: 0.76rem;
  //background: #FFFFFF;
  background: #F8F9FD;
  //box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.03rem;
  border: 1px solid #3967A2;
  box-sizing: border-box;
  padding: 0.03rem;
  display: flex;

  img {
    width: 0.68rem;
    height: 0.68rem;
  }
}

.request {
  padding: 0 0.16rem 0.7rem;

  img {
    width: 3.43rem;
    height: 2.4rem;
  }
}

.btn {
  width: 100%;
  height: 0.56rem;
  font-size: 0.15rem;
  margin-top: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.21rem;
  background: #3967A2;
  position: fixed;
  bottom: 0px;
}

</style>
