<template>
  <div>
    <div :class="{quoteCardBtn:isPadding==true}" :style="{backgroundColor:bgColor}" class="quoteCard">
      <div v-for="(item,index) in list" :key="index" :class="{'line': isShowInput}" class="quoteCard-item">
        <div class="cardContent">
          <div class="cardTitle">
            {{ item.type == 0 ? '主材' : '辅材' }}{{ item.order }}
          </div>
          <div class="cardImg" @click="jumpStoneDetails(item.engineeringId,item.engineeringItemId)">
            <!-- <img :src="item.frontCoverImg" alt=""> -->
            <div>
              <van-image :src="item.frontCoverImg" class="borderImg" height="1.02rem" radius="0.06rem" width="1.22rem"/>
            </div>
            <div class="quotationMsg">
              <div class="sName">
                <p>
                  {{ item.basItemName }}
                </p>
                <p>
                  预用量：{{ item.estimatedTotalArea }}㎡
                </p>
              </div>
              <div class="specs-wrapper">
                <div class="specs">
                  <p>
                    区域：{{ item.applicableArea }}
                  </p>
                  <p>
                    <!-- 厚度：≥17mm 主规 -->
                    厚度：{{ item.thickness }}mm
                  </p>
                  <p>
                    <!-- 规格：800mm x 800mm -->
                    规格：{{ item.mainSpecification }}
                  </p>
                </div>
                <img v-if="isShowArrow" :src="arrowIcon" alt="" style="width:0.1rem; height:auto;">
              </div>
            </div>
          </div>
        </div>

        <div v-if="isShowInput">
          <!-- 报价输入框 -->
          <!--          未报价和已经报价，报价超过时间都可以点击，报价结束不能点击-->
          <!--          v-if="item.quotedPriceStatus!=2"-->
          <div class="input">
            <van-field
                v-model="item.quotedPrice"
                :formatter="formatter"
                clearable
                label="￥"
                label-align="center"
                label-width="0.29rem"
                placeholder="请输入报价金额"
                type="number"
                :readonly="item.quotedPriceStatus==2"
            >
              <template #extra>
                <span>㎡</span>
              </template>
            </van-field>

            <van-field
                v-model="item.scProductLink"
                clearable
                maxlength="300"
                :readonly="item.quotedPriceStatus==2"
                placeholder='请输入"链石"商品链接'
            >
            </van-field>

            <van-field
                v-model="item.quotedPriceRemark"
                :placeholder="placeholder"
                clearable
                maxlength="300"
                rows="3"
                :readonly="item.quotedPriceStatus==2"
                type="textarea"
            />
          </div>
          <div v-if="isShowInput" class="uploadBox">
            <p v-if="item.quotedPriceStatus!=2" class="uploadTitle">
              请上传品相图、彩平图、切割图（含有品质标注）
            </p>
            <van-uploader v-if="item.quotedPriceStatus!=2||item.quotedPriceImgs.length" v-model="item.quotedPriceImgs"
                          :readonly="devType.isAndroid"
                          :after-read="afterRead" :before-read="beforeRead"
                          :max-count="6"
                          :preview-size="['1.04rem', '1.04rem']" result-type="file">
              <div v-if="item.quotedPriceStatus!=2" class="uploadClass" @click="uploadClick(index)"></div>
            </van-uploader>
          </div>
        </div>

      </div>
    </div>
    <div v-if="list.length>0">
      <div v-if="isShowInput&&check&&list[0].quotedPriceStatus != 2" style="height: 0.56rem">
        <van-button class="btn" @click="checkPrice">
          确认报价
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, watch} from 'vue';
import {Toast} from 'vant';
import {useRouter} from 'vue-router'
import {quotedPrice, uploadFile} from '@/api/quitedPrice'
import {versions} from '@/utils/toolsValidate'
import {pickImagesAndUpload} from '@/utils/interaction'

export default defineComponent({
  name: 'quoteCard',
  props: {
    bgColor: {
      type: String,
      default: '#F8F9FD'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    // 展示的数据
    dataList: {
      type: [Object, Array],
      default() {
        return []
      }
    },
    // 是否展示价格输入框
    isShowInput: {
      type: Boolean,
      default: false
    },
    // 是否有内边距
    isPadding: {
      type: Boolean,
      default: true
    },
    // 是否展示箭头
    isShowArrow: {
      type: Boolean,
      default: false
    },
    endInquiryId: {
      type: String,
      // default: false
    },
    // 是否在页面内
    isInside: {
      type: Boolean,
      default: false
    }
  },
  setup(props, conText) {
    const router = useRouter();
    const dev = versions()
    console.log('当前', dev)
    const state = reactive({
      uploadDataIndex: '',
      placeholder: '请填写报价备注，例：交货周期、出材率对报价金额影响点、付款条件有疑问等',
      currentValue: props.value,
      show1: 1,
      show: 1,
      dd: '',
      message: '',
      pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
      arrowIcon: require('../../../assets/quotedPrice/icon_left.png'),
      // api: new ApiQuotation(),
      check: true,
      devType: dev
    });
    const jumpStoneDetails = (engineeringId, engineeringItemId) => {
      if (props.isShowArrow) {
        if (props.isInside) {
          let foo = {
            engineeringId: engineeringId,
            engineeringItemId: engineeringItemId,
          }
          conText.emit('chose', foo)
        } else {
          router.push({
            path: '/stoneDetail',
            query: {
              endInquiryId: props.endInquiryId,
              engineeringId: engineeringId,
              engineeringItemId: engineeringItemId,
            }
          })
        }
      }
    }
    watch(() => props.value, (nval) => {
      state.currentValue = nval;
    });
    watch(() => props.dataList, (nval) => {
      state.list = nval;
      if (!nval) return
      for (let i = 0; i < state.list.length; i++) {
        state.list[i].quotedPriceImgs = state.list[i].quotedPriceImgs || []
      }
    }, {immediate: true});
    onMounted(() => {
      if (props.isShowInput && state.devType.isAndroid) {
        console.log(window.uploadSimpleImageSuccess)
        // watchWindow();
        window.uploadSimpleImageSuccess = watchWindow;
      }
    });
    //手动上传文件
    const afterRead = (file) => {
      if (!(file instanceof Array)) {
        file = [file]
      }
      console.log(file)
      for (let i = 0; i < file.length; i++) {
        let fileUrl = file[i].file
        file[i].status = 'uploading'
        file[i].message = '上传中...'
        let formData = new FormData()
        formData.append('file', fileUrl)
        formData.append('module', 'lsdc-inquiry')
        formData.append('moduleSub', 'quoted-price-img')

        uploadFile(formData).then(res => {
          if (res.result) {
            file[i].url = res.data.fileUrl
            file[i].status = 'done'
            file[i].message = '上传成功'
          } else {
            file[i].status = 'failed'
            file[i].message = '上传失败'
          }
        })
      }
    };

    //上传前校验
    const beforeRead = (file) => {
      if (!(file instanceof Array)) {
        file = [file]
      }
      for (let i = 0; i < file.length; i++) {
        const isImgType = file[i].type === "image/jpeg" || file[i].type === "image/png" || file[i].type === "image/jpg";
        const isLt20M = file[i].size / 1024 / 1024 < 20;
        if (!isImgType) {
          Toast('上传图片只能是 JPG或PNG或JPEG 格式');
          return false;
        }
        if (!isLt20M) {
          Toast('上传图片大小不能超过 20MB!');
          return false;
        }
      }
      return true;
    }

    // 格式化报价金额
    const formatter = (val) => {
      if (parseInt(val) >= 100000000) {
        return 99999999.99
      }
      return val.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/-/g, '')
    }

    const uploadClick = (uploadDataIndex) => {
      console.log(state.uploadDataIndex)


      if (state.devType.isAndroid) {
        console.log(state.list[uploadDataIndex].quotedPriceImgs)
        let imgUpImg = 6 - (state.list[uploadDataIndex].quotedPriceImgs.length || 0);
        console.log(imgUpImg)
        if (imgUpImg <= 6) {
          let obj = {
            uploadUrl: 'apis/home/UploadImgToOss?uploadType=multi&imgType=Goods',
            jsCallback: 'uploadSimpleImageSuccess',
            maxNum: imgUpImg,
          }
          pickImagesAndUpload(obj)
          // state.uploadDataIndex = uploadDataIndex;
          window.uploadDataIndex = uploadDataIndex;
          console.log('下标', window.uploadDataIndex)
        }

      }
    }

    /**
     * TODO 监听图片上传完成后
     */
    const watchWindow = (msg, response) => {
      if (msg !== "null" && msg !== "(null)" && msg !== "OK") {
        window.LsError = msg;
        return;
      }
      let arr = response.split(';');
      arr.pop();
      if (window.uploadDataIndex >= 0) {
        let imgs = arr.map(item => {
          return {
            url: item
          }
        })
        state.list[window.uploadDataIndex].quotedPriceImgs = state.list[window.uploadDataIndex].quotedPriceImgs.concat(imgs)
      }
    }

    //报价事件
    const checkPrice = () => {
      let arr = state.list;
      let itemSuppliers = [];
      for (let i = 0; i < arr.length; i++) {
        let quotedPriceImgs = arr[i].quotedPriceImgs.map(item => {
          return {
            img: item.url,
            quotedPriceImgId: item.quotedPriceImgId
          }
        })
        itemSuppliers.push({
          engineeringItemId: arr[i].engineeringItemId,
          quotedPrice: arr[i].quotedPrice,
          quotedPriceRemark: arr[i].quotedPriceRemark,
          scProductLink: arr[i].scProductLink,
          quotedPriceImgs: quotedPriceImgs
        })
      }


      quotedPrice({endInquiryId: props.endInquiryId, itemSuppliers: itemSuppliers}).then(res => {
        if (res.result) {
          console.log(res)
          Toast({
            type: 'success',
            message: '报价成功',
            forbidClick: true,
            duration: 1500,
            onClose: function () {
              state.list = [];
              conText.emit('clearData')
            }
          })
        }
      })


    }
    return {
      jumpStoneDetails,
      afterRead,
      beforeRead,
      checkPrice,
      formatter,
      uploadClick,
      watchWindow,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="less" scoped>
.quoteCard {
  padding: 0 0 0.1rem;
}

.quoteCardBtn {
  //background: #F8F9FD;
  padding: 0.05rem 0 0.57rem;
}

.quoteCard-item {
  padding: 0 0.16rem;
  width: 100%;
  box-sizing: border-box;
}

.line {
  border-bottom: 0.02rem dashed #3967A2;

  &:last-child {
    border-bottom: none;
  }
}

.input {
  margin-bottom: 0.16rem;
  margin-top: 0.16rem;

  ::v-deep .van-cell {
    padding: 0.07rem 0.1rem;
    border: 1px solid #3967A2;
    box-shadow: 0px 0px 0.04rem 0px #FFF1E1;
    border-radius: 0.03rem;
    margin-bottom: 0.16rem;

    &:last-child {
      height: 1.02rem;
      margin-bottom: 0;
      border: 1px solid #3967A2;
      border-radius: 0.04rem;
    }
  }

  ::v-deep .van-field__label {
    width: fit-content !important;
  }
}

.uploadBox {
  width: 100%;

  .uploadTitle {
    margin-bottom: 0.08rem;
    font-size: 0.14rem;
    font-weight: 400;
    color: #666666;
    line-height: 0.2rem;
  }
}

.uploadClass {
  width: 1.04rem;
  height: 1.04rem;
  background-image: url("../../../assets/quotedPrice/upload.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.btn {
  width: 100%;
  height: 0.56rem;
  font-size: 0.15rem;
  margin-top: 0.32rem;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.21rem;
  background: #3967A2;
  position: fixed;
  bottom: 0px;
}

.labelClass {
  width: 0.29rem;
  text-align: center;
}

.cardContent {
  height: 1.33rem;
  margin-bottom: 0.16rem;
}

.cardTitle {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3967A2;
  line-height: 0.2rem;
  margin: 0.1rem 0 0.06rem 0;

}

.cardImg {
  //width: 210px;
  margin-bottom: 0.17rem;
  height: 1rem;
  display: flex;

  .borderImg {
    background: #3967A2;
    border-radius: 0.04rem;
    border: 1px solid #3967A2;

  }

  .quotationMsg {
    width: 2.07rem;
    margin-left: 0.16rem;
    box-sizing: border-box;

    .sName {
      display: flex;
      justify-content: space-between;

      height: 0.17rem;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      line-height: 0.17rem;
      margin-bottom: 0.06rem;

      p:first-child {
        color: #333333;
      }

      p:last-child {
        color: #3967A2;
        font-weight: 400;
      }
    }

    .specs {
      //display: flex;
      //justify-content: space-between;
      // height: 17px;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 0.17rem;
      color: #666666;

      p {
        margin-bottom: 0.06rem;
      }
    }
  }
}

.specs-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
</style>
